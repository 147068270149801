.ad-card {
    font-family: 'Arial, Helvetica, sans-serif';
    width: 360px; /* Full card width */
    height: 360px; /* Full card height */
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    background: white;
    cursor: pointer;
    border: 5px solid white;
}

.ad-image-container {
    width: 100%;
    /* Calculate the height based on the width to maintain 16:9 ratio */
    height: 212px; /* This will be less than or equal to 507px */
    overflow: hidden;
}

.ad-image {
    width: 100%;
    height: 212px;
    object-fit: cover;
}

.ad-content {
    padding: 16px;
    background-color: white;
    flex-grow: 1; /* Allows content to expand to the remaining height */
    display: flex;
    flex-direction: column;
}

.ad-headline {
    font-size: 20px;
    color: #333333;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin: 0 0 8px 0;
}

.ad-description {
    font-size: 14px;
    color: #666666;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin: 0;
    flex-grow: 1; /* Allows the description to expand and push the footer to the bottom */
}

.ad-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.ad-subtext {
    font-size: 12px;
    color: #888888;
}

.ad-button {
    background-color: transparent;
    color: #000000;
    border: 1px solid #000000;
    padding: 4px 8px;
    border-radius: 5px;
    font-weight: bold;
    cursor: pointer;
    font-size: 9px;
    transition: all 0.3s ease;
    margin-left: auto; /* Aligns button to the right */
}

.ad-button:hover {
    background-color: #000000;
    color: #FFFFFF;
}

.selected {
    border: 5px solid #4CAF50; /* Green border for selected card */
}

